import React from 'react';

import { REQUIREMENT_TYPE } from '../enums';
import {
  AddressAreaRequirementForm,
  BooleanRequirementForm,
  ChoiceRequirementForm,
  DateRequirementForm,
  EmailRequirementForm,
  FileRequirementForm,
  InvoiceRequirementForm,
  LoginRequirementForm,
  PaymentRequirementForm,
  PreconditionsRequirementForm,
  TextfieldRequirementForm,
  VoucherRequirementForm,
} from './forms';

const RequirementForm = ({
  disabled,
  formRef,
  requirement,
  requirementType,
  onSubmit,
}) => {
  if (requirementType === REQUIREMENT_TYPE.AddressArea) {
    return (
      <AddressAreaRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          countries: requirement?.countries || [],
          description: requirement?.description || '',
          hasDeliveryArea: requirement?.hasDeliveryArea || false,
          isDeliveryAddress: requirement?.isDeliveryAddress || false,
          name: requirement?.name || '',
          pickUpAllowed: requirement?.pickUpAllowed || false,
          addressLevel: requirement?.addressLevel || 'ADR|SHBTC',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              countries: formValues.countries,
              description: formValues.description,
              hasDeliveryArea: formValues.hasDeliveryArea,
              isDeliveryAddress: formValues.isDeliveryAddress,
              name: formValues.name,
              pickUpAllowed: formValues.pickUpAllowed,
              addressLevel: formValues.addressLevel,
            },
            REQUIREMENT_TYPE.AddressArea,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Boolean) {
    return (
      <BooleanRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          errorMessage: requirement?.errorMessage || '',
          isPrechecked: requirement?.isPrechecked || false,
          name: requirement?.name || '',
          newsletter: requirement?.newsletter || '',
          required: requirement?.required || false,
          uniqueKey: requirement?.key || '',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              errorMessage: formValues.errorMessage,
              isPrechecked: formValues.isPrechecked,
              name: formValues.name,
              newsletter: formValues.newsletter,
              required: formValues.required,
              key: formValues.uniqueKey,
            },
            REQUIREMENT_TYPE.Boolean,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Choice) {
    return (
      <ChoiceRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          choices: requirement?.choices.join(';') || '',
          description: requirement?.description || '',
          name: requirement?.name || '',
          uniqueKey: requirement?.key || 'other',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              choices: formValues.choices.split(';'),
              description: formValues.description,
              name: formValues.name,
              key: formValues.uniqueKey,
            },
            REQUIREMENT_TYPE.Choice,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Date) {
    return (
      <DateRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          endDay: requirement?.timeFrameEnd || 30,
          name: requirement?.name || '',
          startDay: requirement?.timeFrameStart || 0,
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              name: formValues.name,
              timeFrameEnd: formValues.endDay,
              timeFrameStart: formValues.startDay,
            },
            REQUIREMENT_TYPE.Date,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Email) {
    return (
      <EmailRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          name: requirement?.name || '',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              name: formValues.name,
            },
            REQUIREMENT_TYPE.Email,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.File) {
    return (
      <FileRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          name: requirement?.name || '',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              name: formValues.name,
            },
            REQUIREMENT_TYPE.File,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.InvoiceDetails) {
    return (
      <InvoiceRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          name: requirement?.name || '',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              name: formValues.name,
            },
            REQUIREMENT_TYPE.InvoiceDetails,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Login) {
    return (
      <LoginRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          name: requirement?.name || '',
          identityLevel: requirement?.identityLevel || '',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              name: formValues.name,
              identityLevel: formValues.identityLevel,
            },
            REQUIREMENT_TYPE.Login,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Payment) {
    return (
      <PaymentRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          amount: requirement?.amount || 0,
          description: requirement?.description || '',
          name: requirement?.name || '',
          oneShotOptions: requirement?.oneShotPaymentOptions || [],
          recurringOptions: requirement?.recurringPaymentOptions || [],
          shouldCaptureIban: requirement?.shouldCaptureIban || false
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              amount: formValues.amount,
              description: formValues.description,
              name: formValues.name,
              oneShotPaymentOptions: formValues.oneShotOptions,
              recurringPaymentOptions: formValues.recurringOptions,
              shouldCaptureIban: formValues.shouldCaptureIban
            },
            REQUIREMENT_TYPE.Payment,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Preconditions) {
    return (
      <PreconditionsRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          name: requirement?.name || '',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              name: formValues.name,
            },
            REQUIREMENT_TYPE.Preconditions,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Textfield) {
    return (
      <TextfieldRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          name: requirement?.name || '',
          uniqueKey: requirement?.key || 'other',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              key: formValues.uniqueKey,
              name: formValues.name,
            },
            REQUIREMENT_TYPE.Textfield,
          );
        }}
      />
    );
  }
  if (requirementType === REQUIREMENT_TYPE.Voucher) {
    return (
      <VoucherRequirementForm
        disabled={disabled}
        formRef={formRef}
        initialValues={{
          description: requirement?.description || '',
          name: requirement?.name || '',
        }}
        onSubmit={formValues => {
          onSubmit(
            {
              ...requirement,
              description: formValues.description,
              name: formValues.name,
            },
            REQUIREMENT_TYPE.Voucher,
          );
        }}
      />
    );
  }

  return null;
};

export default RequirementForm;
